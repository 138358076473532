<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap text-center">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowDetailForm(row.item)"
            >
              View
            </b-button>
            <b-button
              v-if="parseInt(row.item.call_attempt) >= 3"
              size="sm"
              variant="danger"
              @click="onShowCancelForm(row.item)"
            >
              Cancel
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-view-detail"
      title="View Details"
      scrollable
      no-close-on-backdrop
      size="lg"
    >
      <b-tabs>
        <b-tab
          title="Request Details"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Request Date</label>
                <b-input
                  :value="dateTimeFormatter(selected.call_request?.created_at)"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.call_request?.customer?.first_name} ${selected.call_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Mobile Number</label>
                <b-input
                  :value="selected.call_request?.customer?.mobile_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.call_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.call_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Communication Logs"
        >
          <b-table
            ref="customerLogTable"
            hover
            responsive
            class="mt-2"
            :per-page="tableCSLogSettings.perPage"
            :current-page="tableCSLogSettings.currentPage"
            :items="MyCommunicationLogProvider"
            :fields="tableCSLogSettings.fields"
            :sort-by.sync="tableCSLogSettings.sortBy"
            :sort-desc.sync="tableCSLogSettings.sortDesc"
            :sort-direction="tableCSLogSettings.sortDirection"
            :filter="tableCSLogSettings.filter"
            :filter-included-fields="tableCSLogSettings.filterOn"
            show-empty
          >

            <template #cell(index)="data">
              {{ tableCSLogSettings.currentPage * tableCSLogSettings.perPage - tableCSLogSettings.perPage + (data.index + 1) }}
            </template>

            <template #cell()="data">
              <div class="text-nowrap">
                {{ data.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onViewCommunicationLog(row.item)"
                >
                  View
                </b-button>
              </div>
            </template>

          </b-table>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableCSLogSettings.perPage"
                  :options="tableCSLogSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableCSLogSettings.currentPage"
                :total-rows="tableCSLogSettings.totalRows"
                :per-page="tableCSLogSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-communication-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel</label>
            <b-input
              :value="selected.log?.channel"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="moda-cancel-request"
      title="Cancel Request"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:1000"
              >
                <div class="form-group">
                  <label for="remarks">Remarks</label>
                  <b-textarea
                    id="remarks"
                    v-model="cancelForm.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Proceed
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, UserCallRequestService, SharedLogService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserCallRequest',

  middleware: ['auth', 'user'],

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'Call Requests'
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      selected: {
        call_request: null,
        log: null
      },
      cancelForm: {
        id: 0,
        remarks: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status', sortable: true },
          { key: 'customer.first_name', label: 'first name' },
          { key: 'customer.last_name', label: 'last name' },
          { key: 'customer.mobile_number', label: 'mobile number' },
          { key: 'call_attempt', label: 'unsuccessful call attempt' },
          { key: 'last_call' },
          { key: 'last_status' },
          { key: 'last_agent' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableCSLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'channel' },
          { key: 'call_status' },
          { key: 'unsuccessful_reason_name', label: 'unsuccessful reason' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserCallRequestService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async MyCommunicationLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getCustomerLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.selected.call_request.customer?.id,
            request_id: this.selected.call_request.id
          })
        )
        this.tableCSLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onShowCancelForm (current) {
      if (parseInt(current.call_attempt) < 3) {
        return
      }
      this.cancelForm.id = current.id
      this.cancelForm.remarks = ''
      this.$bvModal.show('moda-cancel-request')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want cancel request?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserCallRequestService.put(this.cancelForm).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('moda-cancel-request')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onShowDetailForm (current) {
      this.selected.call_request = { ...current }
      this.$bvModal.show('modal-view-detail')
    },

    onViewCommunicationLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-communication-log')
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    }
  }
}
</script>
